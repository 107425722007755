<template>
  <div id="app">
    <router-view />
  </div>
</template>
<style lang="less">
@import './styles/global.less';
#app {
  width: 100%;
  height: 100% !important;
}
// 备注信息样式
.remarksAlink{
    // margin-left: 20px;
    color:#FF7A00;
    font-size: 14px;
    a{
      text-decoration: underline;
    }
  }
</style>
<script>
export default {
  data() {
    return {
      home: 'home'
    }
  },
  mounted() {
    // global.responsivePage({
    //   selector: '#app', // string 内容区域的父级元素, 接受任何合法的CSS选择器
    //   sliceWidth: 1920, // number 切图宽度(单位是px), 即设计稿宽度
    //   center: false // boolean 页面宽度超过切图宽度后, 是否不再适配宽度居中显示在页面中
    // })
  }
}
</script>
