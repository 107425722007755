import Vue from 'vue'
import Vuex from 'vuex'
let Base64 = require('js-base64').Base64
Vue.use(Vuex)
let num = 0
export default new Vuex.Store({
  state: {
    userInfo: {}, // 个人信息
    breadcrumb: [],
    productInfo: {}, // 产品信息
    globalLoading: '',
  },
  mutations: {
    setUserInfo(state, obj) {
      state.userInfo = obj
    },
    setProductInfo(state, obj) {
      state.productInfo = obj
    },
    setBreadcrumb(state, array) {
      state.breadcrumb = array
      sessionStorage.setItem('=-dwnwnxi0=', Base64.encode(array))
      // let list = Base64.decode(sessionStorage.getItem('=-dwnwnxi0='))
      // console.log(list.split(','))
    },
    setGolbalLoading(state, loading) {
      num = num + loading
      if(num == 0) {
        state.globalLoading = false
      } else {
        state.globalLoading = true
      }
      
    },
     // 登录重置loading
     resetLogin (state) {
      num = 0
      state.globalLoading = false
    }
  },
  actions: {},
  modules: {}
})
