export default {
  permissionResetPassword: {
    url: '/permission/reset_password',
    isMock: false,
    tips: '修改密码'
  },
  dealerUserManagePasswd: {
    url: '/password/reset',
    isMock: false,
    tips: '密码重置'
  },
  getPicAuthCode: {
    url: '/system/image_code',
    isMock: false,
    tips: '获取验证码'
  },
  systemRsa: {
    url: '/system/rsa',
    isMock: false,
    tips: '获取key'
  },
  manageLogin: {
    url: '/system/login',
    isMock: false,
    tips: '登录'
  },
  loginOut: {
    url: '/system/login_out',
    isMock: false,
    tips: '退出登录'
  },
  supplierSave: {
    url: '/supplier_side/save',
    isMock: false,
    tips: '保存供应商信息'
  },
  zoneList: {
    url: '/zone_list',
    isMock: false,
    tips: '地区下拉列表'
  },
  getMenuList: {
    url: '/permission/supplier_func/rela',
    isMock: false,
    tips: '获取菜单列表'
  },
  usageList: {
    url: '/usage',
    isMock: false,
    tips: '用量表列表'
  },
  uploadFile: {
    url: '/supplier_factory/upload/file',
    isMock: false,
    tips: '图片上传'
  },
  uploadFile2: {
    url: '/public/upload/file',
    isMock: false,
    tips: '图片上传'
  },
  productTemplateImport: {
    url: '/product/template_import',
    isMock: false,
    tips: '产品管理-模板导入'
  },
  supplierBusinessscope: {
    url: '/supplier/businessscope',
    isMock: false,
    tips: '经营范围查询'
  },
  supplierBusinessscopeList: {
    url: '/supplier/businessscope_list',
    isMock: false,
    tips: '经营范围下拉列表'
  },
  getSupplier: {
    url: '/supplier/get',
    isMock: false,
    tips: '供应商详情'
  },
  supplierTemlpateDownload: {
    url: '/supplier_temlpate/download',
    isMock: false,
    tips: '供应商模板下载'
  },
  importSupplier: {
    url: '/import_supplier',
    isMock: false,
    tips: '导入供应商'
  },
  exportSupplier: {
    url: '/export_supplier',
    isMock: false,
    tips: '导出厂家审批表'
  },
  getProductList: {
    url: '/product/product_list',
    isMock: false,
    tips: '供应商产品列表'
  },
  searchProduct: {
    url: '/product/search_product',
    isMock: false,
    tips: '基础信息-产品名称查询'
  },
  searchProductFactory: {
    url: '/product/search_product_factory',
    isMock: false,
    tips: '查询产品厂家'
  },
  productProductBase: {
    url: '/product/product_basc',
    isMock: false,
    tips: '保存经营返回'
  },
  productProductLicense: {
    url: 'product/product_license',
    isMock: false,
    tips: '保存产品注册证信息'
  },
  productProductBasc: {
    url: '/product/product_basc',
    isMock: false,
    tips: '产品基础信息'
  },
  productSearchProductMessage: {
    url: '/product/search_product_message',
    isMock: false,
    tips: '查询产品信息'
  },
  productProductDetail: {
    url: '/product/product_detail',
    isMock: false,
    tips: '产品审核信息详情'
  },
  productProductDelete: {
    url: '/product/product_delete',
    isMock: false,
    tips: '删除产品'
  },
  productSearchDept: {
    url: '/product/search_dept',
    isMock: false,
    tips: '产品临床使用科室'
  },
  productProductAgreement: {
    url: '/product/product_agreement',
    isMock: false,
    tips: '保存产品经销协议信息'
  },
  productProductRecord: {
    url: '/product/product_record',
    isMock: false,
    tips: '产品备案凭证信息保存'
  },
  productCodeRule: {
    url: '/product/product_code_rule',
    isMock: false,
    tips: '产品条码规则下拉'
  },
  getBusinessDetail: {
    url: '/supplier/business_detail',
    isMock: false,
    tips: '获取业务明细'
  },
  selectUsage: {
    url: '/usage/get',
    isMock: false,
    tips: '用量表下拉(查询条件)'
  },
  getHospital: {
    url: '/customer_list',
    isMock: false,
    tips: '用量表医院下拉(查询条件)'
  },
  changeStatus: {
    url: '/usage/operate',
    isMock: false,
    tips: '用量表确认/推送'
  },
  getUsageDetail: {
    url: '/usage/operate',
    isMock: false,
    tips: '获取用量表详情'
  },
  meterSupplement: {
    url: '/usage/operate',
    isMock: false,
    tips: '用量表补充'
  },
  addLineCode: {
    url: '/product/search_barcode',
    isMock: false,
    tips: '条形码补充'
  },
  getBillingInformation: {
    url: '/supplier/invoice',
    isMock: false,
    tips: '开票信息列表'
  },
  supplierInvoice: {
    url: '/supplier_invoice',
    isMock: false,
    tips: '开票信息列表'
  },
  supplierInvoiceDetailLook: {
    url: '/supplier_invoice/detail',
    isMock: false,
    tips: '查看发票',
  },
  getBillingInformationDetail: {
    url: '/supplier/invoice/detail',
    isMock: false,
    tips: '开票详情'
  },
  getMoneyNumber: {
    url: '/supplier/invoice/value',
    isMock: false,
    tips: '获取关联采购单的合计金额'
  },
  addBillInformation: {
    url: '/supplier/invoice',
    isMock: false,
    tips: '开票',
    methods: {
      get: '发票列表',
      post: '新增发票',
      put: '修改发票'
    }
  },
  getShopSelect: {
    url: '/supplier/purchase_invoice',
    isMock: false,
    tips: '获取采购订单下拉列表'
  },
  invoiceSaleList: {
    url: '/invoice/sale_list',
    isMock: false,
    tips: '客户发票关联销售订单 列表'
  },
  getOrderList: {
    url: '/supplier/home/dashboard',
    isMock: false,
    tips: '获取订单统计, 待办事项'
  },
  getTableList: {
    url: '/supplier/home/system_warning',
    isMock: false,
    tips: '获取系统预警表格列表'
  },
  getTableModelList: {
    url: '/supplier/home/system_warning/list',
    isMock: false,
    tips: '供应商端首页-预警弹窗列表'
  },
  getDailySales: {
    url: '/supplier/home/daily_sales',
    isMock: false,
    tips: '获取日销量统计'
  },
  getMonthlySales: {
    url: '/supplier/home/monthly_sales',
    isMock: false,
    tips: '获取月销量统计'
  },
  productSave: {
    url: '/product/save',
    isMock: false,
    tips: '产品编辑保存'
  },
  pruchaseOrderManage: {
    url: '/pruchase_order_manage',
    isMock: false,
    tips: '采购订单列表'
  },
  pruchaseOrderDetail: {
    url: '/pruchase_order_detail',
    isMock: false,
    tips: '采购订单详情'
  },
  orderLogistics: {
    url: '/order/logistics',
    isMock: false,
    tips: '物流信息新增/删除'
  },
  pruchaseOrderConfirm: {
    url: '/pruchase_order/confirm',
    isMock: false,
    tips: '采购订单确认'
  },
  productSearchBarcode: {
    url: '/product/search_barcode',
    isMock: false,
    tips: '条形码查询'
  },
  supplierProductSelect: {
    url: '/product/select',
    isMock: false,
    tips: '供应商的产品下拉'
  },
  SKUSelect: {
    url: '/product_code/select',
    isMock: false,
    tips: '供应商下所有规格型号下拉选择'
  },
  pruchaseOrderExport: {
    url: '/pruchase_order/export',
    isMock: false,
    tips: '导出采购单'
  },
  getProductNameSelect: {
    url: '/product/select',
    isMock: false,
    tips: '产品名称模糊查询'
  },
  productTemplateDownload: {
    url: '/product/template_download',
    isMock: false,
    tips: '产品文档下载'
  },
  productBusinessReviewExport: {
    url: '/product/business_review_export',
    isMock: false,
    tips: '产品首营审批导出'
  },
  productUnit: {
    url: '/product/unit',
    isMock: false,
    tips: '产品单位'
  },
    // /announcement_manage
    AnnouncementManage: {
      url: '/announcement_manage',
      isMock: false,
      tips: '消息管理列表',
    },
    // /announcement_detail
    AnnouncementDetail: {
      url: '/announcement_detail',
      isMock: false,
      tips: '查看消息',
    },
    // /file_manage
    UploadFile: {
      url: '/upload_file',
      isMock: false,
      tips: '消息管理上传文件',
    },
    FileManage: {
      url: '/file_manage',
      isMock: false,
      tips: '文件列表',
    },
    // /file_detail
    FileDetail: {
      url: '/file_detail',
      isMock: false,
      tips: '文件详情',
    },
}
