/*
 *  debounce：函数防抖
 *  @params
 *         fn：要执行的函数
 *         wait：间隔等待时间
 *         immediate：在开始边界还是结束边界触发执行(true=>开始边界)
 * */
export function debounce(fn, wait, immediate) {
  let result = null
  let timeout = null
  return function(...args) {
    let context = this
    let now = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      if (!immediate) result = fn.call(context, ...args)
    }, wait)
    if (now) result = fn.call(context, ...args)
    return result
  }
}
/*
 *  throttle：函数节流
 *  @params
 *         fn：要执行的函数
 *         wait：间隔等待时间
 * */
export function throttle(fn, wait) {
  let canRun = true // 通过闭包保存一个标记
  return function() {
    if (!canRun) return // 在函数开头判断标记是否为true，不为true则return
    canRun = false // 立即设置为false
    setTimeout(() => {
      // 将外部传入的函数的执行放在setTimeout中
      fn.apply(this, arguments)
      // 最后在setTimeout执行完毕后再把标记设置为true(关键)表示可以执行下一次循环了。当定时器没有执行的时候标记永远是false，在开头被return掉
      canRun = true
    }, wait)
  }
}
function onlyNumFormat(number) {
  number = (number + '').replace(/[^0-9]/g, '')
  return number
}
// description格式化金额
// numbe要格式化的数字
// decimal保留几位小数默认0位
// decPoint小数点符号默认.
// thousandsSep千分位符号默认为
export const numFormat = (number, decimals = 0, decPoint = '.', thousandsSep = ',') => {
  if (number === 0) {
    return number
  }
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')
  let n = !isFinite(+number) ? 0 : +number
  let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  // let sep = typeof thousandsSep === 'undefined' ? '' : thousandsSep
  let dec = typeof decPoint === 'undefined' ? '.' : decPoint
  let s = ''
  let toFixedFix = function(n, prec) {
    let k = Math.pow(10, prec)
    return '' + Math.ceil(n * k) / k
  }
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  // let re = /(-?\d+)(\d{3})/
  // while (re.test(s[0])) {
  //   s[0] = s[0].replace(re, '$1' + sep + '$2')
  // }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}
function formatMoney(num, sign = false) {
  if (!num && num != 0) return ''
  if (isNaN(num)) return num
  num = num * 1
  // 保留两位小数, 四舍五入
  num = num.toFixed(2)
  // 转数值型
  num = parseFloat(num)
  // 格式化为千分位
  num = num.toLocaleString()
  // 补小数位
  if (!num.includes('.')) {
    num += '.00'
  }
  if (num.split('.')[1].length == 1) {
    num += '0'
  }
  // 补 ¥
  if (sign) {
    num = '¥' + num
  }
  return num
}
export default {
  throttle,
  debounce,
  numFormat,
  onlyNumFormat,
  formatMoney
}
