export default {
  // factoryManage: {
  //   url: '/factory_manage',
  //   isMock: false,
  //   tips: '获取验证码'
  // },
  // 用户列表api
  permissionUser: {
    url: '/permission/user',
    isMock: false,
    tips: '获取用户列表/修改指定用户/添加用户/删除指定用户'
  },
  // 用户所属角色列表
  permissionRoleList: {
    url: '/permission/role_list',
    isMock: false,
    tips: '获取角色列表'
  },
  permissionUserRole: {
    url: '/permission/user_role',
    isMock: false,
    tips: '获取角色下所有的用户/设置角色下的用户'
  },
  permissionQueryUser: {
    url: '/permission/query_user',
    isMock: false,
    tips: '查询用户'
  },
  permissionUserid: {
    url: '/permission/user/',
    isMock: false,
    tips: ''
  },
  permissionGetUsersRoles: {
    url: '/permission/get_users_roles',
    isMock: false,
    tips: '添加用户时获取可设置角色'
  },
  permissionWareHouseList: {
    url: '/permission/warehouse_list',
    isMock: false,
    tips: '获取仓库列表'
  },
  // 供应商//所属公司下拉
  supplierList: {
    url: '/supplier_list',
    isMock: false,
    tips: '供应商下拉窗口'
  },

  // 客户管理
  hospitalManage: {
    url: '/hospital_manage',
    isMock: false,
    tips: '新增/修改 医院（营业执照）'
  },
  // 获取医院客户端
  hospitalManageHospitalInformation: {
    url: '/hospital_manage/hospital_information',
    isMock: false,
    tips: '获取医院客户端'
  },

  // 执业许可
  hospitalManageLicensing: {
    url: '/hospital_manage/licensing',
    isMock: false,
    tips: '新增/修改 医院执业许可证'
  },
  // 补充信息
  hospitalManageSupply: {
    url: '/hospital_manage/supply',
    isMock: false,
    tips: '新增/修改 医院补充信息'
  },

  // 医院审批
  hospitalManageApproval: {
    url: '/hospital_manage/approval',
    isMock: false,
    tips: '新增/修改 医院审批'
  },
  hospitalStatus: {
    url: '/hospital_status',
    isMock: false,
    tips: '修改医院状态'
  },

  // 新增/修改 经销商（营业执照）
  distributorManage: {
    url: '/distributor_manage',
    isMock: false,
    tips: '新增/修改 经销商（营业执照）'
  },

  // 客户名称下拉列表接口
  customerList: {
    url: '/customer_list',
    isMock: false,
    tips: '客户名称下拉接口'
  },
  statusList: {
    url: '/status_list',
    isMock: false,
    tips: '状态名称下拉接口'
  },

  // 经销商列表处理
  // distributorManage: {
  //   url: '/distributor_manage',
  //   isMock: false,
  //   tips: '经销商列表',
  // },
  distributorStatus: {
    url: '/distributor_status',
    isMock: false,
    tips: '修改经销商状态'
  },

  zoneList: {
    url: '/zone_list',
    isMock: false,
    tips: '修改经销商状态'
  },

  supplierBusinessscope: {
    url: '/supplier/businessscope',
    isMock: false,
    tips: '经营范围查询'
  },

  // 经营端查询工厂目录
  factoryManageBusiness: {
    url: '/factory_manage/business',
    isMock: false,
    tips: '经营端查询工厂目录'
  },

  // 通过id获取工厂营业执照信息
  factoryManageFactoryIsnformation: {
    url: '/factory_manage/factory_information',
    isMock: false,
    tips: '经营端查询工厂目录'
  },

  // 新建修改 工厂营业执照
  factoryManage: {
    url: '/factory_manage',
    isMock: false,
    tips: '新建修改 工厂营业执照'
  },

  // 工厂生产许可
  factoryManageProductionlicense: {
    url: '/factory_manage/productionlicense',
    isMock: false,
    tips: '经营端查询工厂目录'
  },
  //  获取 工厂备案凭证
  factoryManageFilingcertificate: {
    url: '/factory_manage/filingcertificate',
    isMock: false,
    tips: '新建修改 工厂营业执照/添加/修改 工厂备案信息'
  },

  // 获取 提交 工厂首营审核
  factoryManageFirstmarketingapproval: {
    url: '/factory_manage/firstmarketingapproval',
    isMock: false,
    tips: '获取 工厂首营审核'
  },

  // 获取厂家拉下列表
  factoryList: {
    url: '/factorylist',
    isMock: false,
    tips: '获取 工厂首营审核'
  },
  // 厂家管理删除操作
  factoryDelete: {
    url: '/factory_del',
    isMock: false,
    tips: '厂家管理删除操作'
  },
  // 境外厂家
  factoryManageFactoryInformation: {
    url: '/factory_manage/factory_information',
    isMock: false,
    tips: '厂家管理删除操作'
  },
  // 厂家管理提交
  factorySubmit: {
    url: '/factory/submit',
    isMock: false,
    tips: '厂家管理提交'
  },
  // 境外厂家
  abroadFactorySubmit: {
    url: '/abroad_factory/submit',
    isMock: false,
    tips: '境外厂家提交'
  },
  // 境外厂家
  abroadFactory: {
    url: '/abroad_factory',
    isMock: false,
    tips: '境外厂家'
  },
  // 下拉获取厂家出数据
  getfactoryList: {
    url: '/factory_list',
    isMock: false,
    tips: '境外厂家'
  },
  supplierFactoryList: {
    url: '/supplier/factory_list',
    isMock: false,
    tips: '厂家下拉列表'
  },
  // 厂家管理下载模版
  factoryTemlpateDoenload: {
    url: '/factory_temlpate/doenload',
    isMock: false,
    tips: '模版下载'
  },
  // 厂家管理模版导入
  factoryImport: {
    url: '/factory/import',
    isMock: false,
    tips: '模版下载'
  },
  // 厂家导出审批表
  exportFactory: {
    url: '/export_factory',
    isMock: false,
    tips: '厂家模版导入'
  },
  productFactorySynchro: {
    url: '/product_factory/synchro',
    isMock: false,
    tips: '产品信息同步下拉'
  },
    // 校验产品规格是否使用
  productVerifyProductCode: {
    url: '/product/verify_product_code',
    isMock: false,
    tips: '校验产品规格是否使用',
  },
}
