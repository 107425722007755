export default {
  pruchaseOrderDelivery: {
    url: '/pruchase_order/delivery',
    isMock: false,
    tips: '采购订单发货'
  },
  pruchaseReturnManage: {
    url: '/pruchase_return_manage',
    isMock: false,
    tips: '采购退货列表'
  },
  pruchaseReturnReceive: {
    url: '/pruchase_order/return/receive',
    isMock: false,
    tips: '接收退货'
  },
  pruchaseReturnDetail: {
    url: '/pruchase_return_detail',
    isMock: false,
    tips: '采购退货详情'
  },
  abrodFactoryList: {
    url: '/abrod/factory_list',
    isMock: false,
    tips: '境外厂家下拉'
  }
}
