import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    children: [
      {
        path: '/main',
        name: 'main',
        component: () => import('@/views/main.vue')
      },
      {
        path: '/factoryManage',
        name: 'factoryManage',
        component: () => import('@/views/basisManage/factoryManage.vue'),
        meta: {
          title: '基础信息',
          navName: '厂家管理',
          keepAlive: true
        }
      },
      {
        path: '/addFactory',
        name: 'addFactory',
        component: () => import('@/views/basisManage/factoryChild/addFactory.vue'),
        meta: {
          title: '基础信息',
          navName: '厂家管理'
        }
      },
      {
        path: '/manufacturerManage',
        name: 'manufacturerManage',
        component: () => import('../views/basisManage/manufacturerManage/manufacturerManage.vue'),
        meta: {
          title: '基础信息',
          navName: '厂家管理',
        }
      },
      {
        path: '/outlandManage',
        name: 'outlandManage',
        component: () => import('../views/basisManage/outlandManage.vue'),
        meta: {
          title: '基础信息',
          navName: '境外厂家管理'
        }
      },
      {
        path: '/productManage',
        name: 'productManage',
        component: () => import('@/views/basisManage/productManage.vue'),
        meta: {
          title: '基础信息',
          navName: '产品管理',
          keepAlive: true
        }
      },
      {
        path: '/addProduct',
        name: 'addProduct',
        component: () => import('@/views/basisManage/productChild/addProduct.vue'),
        meta: {
          title: '基础信息',
          navName: '产品管理'
        }
      },
      {
        path: '/editProduct',
        name: 'editProduct',
        component: () => import('@/views/basisManage/productChild/editProduct.vue'),
        meta: {
          title: '基础信息',
          navName: '产品管理'
        }
      },
      {
        path: '/supplierManage',
        name: 'supplierManage',
        component: () => import('@/views/basisManage/supplierManage.vue'),
        meta: {
          title: '基础管理',
          navName: '供应商管理',
          keepAlive: true
        }
      },
      {
        path: '/purchaseOrder',
        name: 'purchaseOrder',
        component: () => import('@/views/orderManage/purchaseOrder.vue'),
        meta: {
          title: '订单管理',
          navName: '采购订单',
          keepAlive: true
        }
      },
      {
        path: '/purchaseOrderDetails',
        name: 'purchaseOrderDetails',
        component: () => import('@/views/orderManage/purchaseOrderDetails.vue'),
        meta: {
          title: '订单管理',
          navName: '采购订单'
        }
      },
      {
        path: '/orderShip',
        name: 'orderShip',
        component: () => import('@/views/orderManage/orderShip.vue'),
        meta: {
          title: '订单管理',
          navName: '采购订单'
        }
      },
      {
        path: '/purchaseReturns',
        name: 'purchaseReturns',
        component: () => import('@/views/orderManage/purchaseReturns.vue'),
        meta: {
          title: '订单管理',
          navName: '采购退货',
          keepAlive: true
        }
      },
      {
        path: '/purchaseReturnsDetails',
        name: 'purchaseReturnsDetails',
        component: () => import('@/views/orderManage/purchaseReturnsDetails.vue'),
        meta: {
          title: '订单管理',
          navName: '采购退货'
        }
      },
      {
        path: '/businessDetails',
        name: 'businessDetails',
        component: () => import('@/views/businessManage/businessDetails.vue'),
        meta: {
          title: '业务管理',
          navName: '业务明细',
          keepAlive: true
        }
      },
      {
        path: '/meterManage',
        name: 'meterManage',
        component: () => import('@/views/settlementCoordination/meterManage.vue'),
        meta: {
          title: '结算协同',
          navName: '用量表管理'
        }
      },
      {
        path: '/addMeter',
        name: 'addMeter',
        component: () => import('@/views/settlementCoordination/addMeter.vue'),
        meta: {
          title: '结算协同',
          navName: '用量表管理'
        }
      },
      {
        path: '/billingInformation',
        name: 'billingInformation',
        component: () => import('@/views/settlementCoordination/billingInformation.vue'),
        meta: {
          title: '结算协同',
          navName: '开票信息',
          keepAlive: true
        }
      },
      {
        path: '/lookListTable',
        name: 'lookListTable',
        component: () => import('../views/settlementCoordination/lookListTable.vue'),
        meta: {
          title: '结算协同',
          navName: '开票信息',
          triName: '供应商发票详情',
        },
      },
      {
        path: '/billingDetails',
        name: 'billingDetails',
        component: () => import('@/views/settlementCoordination/billingDetails.vue'),
        meta: {
          title: '结算协同',
          navName: '开票信息'
        }
      },
      {
        path: '/changePwd',
        name: 'changePwd',
        component: () => import('@/views/changePwd.vue'),
        meta: {
          // title: '结算协同'
          // navName: '开票信息'
        }
      }
    ]
  },
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/newLogin.vue')
  },
  {
    path: '*',
    redirect: '/home'
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path === '/home') {
    next({ path: '/main' })
  } else {
    next()
  }
})
export default router
